.coin-onchain-statuses-comp{
  .modal :first-child.coin-onchain-statuses-modal{
    overflow: auto;
    max-width: 1020px;
  }
  .on,
  .off{
    display: flex;
    justify-content: flex-end;
    span{
      color: #FFF;
      border-radius: 8px;
      padding: 4px 8px;
      background-color: green;
    }
  }
  .off span{
    background-color: red;
  }
}