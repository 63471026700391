.modal :first-child.user-modal{
  overflow: auto;
  max-width: 720px;
  max-height: 95vh;
}
.user-modal{
  .carousel-header{
    margin: 0 0 16px;
  }
  .profile{
    .item{
      input{ margin-top: 4px; }
    }
  }

}