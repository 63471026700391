@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

$spaceing: 120px;

.modal :first-child.modal-main.issue-modal{
  max-width: 680px;
}

.issue-modal{
  .header-buttons{
    margin-bottom: 20px;
    justify-content: space-between;
    display: flex;
    .left-buttons,.right-buttons{
      display: flex;
      .button-element{
        margin-bottom: 0px;
        width: auto;
        height: 32px;
        button{
          padding: 0 8px;
          height: inherit;
          line-height: 32px;
        }
      }
    }
    .left-buttons .button-element{
      margin-right: 8px;
    }
    .right-buttons .button-element{
      margin-left: 8px;
    }
  }
  .messages{
    padding: 0 20px;
    border-radius: 3px;
    border: 1px solid rgba(220,220,220,1);
    position: relative;
    max-height: calc(90vh - 240px);
    overflow: auto;
    .message{
      margin: 12px 0;
      &.left{
        padding-right: $spaceing;
      }
      &.right{
        padding-left: $spaceing;
        .side,
        .date{
          text-align: right;
        }
      }
      .side{
        font-size: 14px;
        margin: 0 0 2px;
      }
      textarea{
        margin: 0;
      }
      &.customer textarea{ 
        background-color: rgba(250,176,51,.15);
        border: 1px solid rgba(250,176,51,1);
      }
      &.merchant textarea{ 
        background-color: rgba(54,167,223,.15);
        border: 1px solid rgba(54,167,223,1);
      }
      &.host textarea{ 
        background-color: rgba($primary-color, .15);
        border: 1px solid $primary-color; 
      }

      .date{
        font-size: 12px;
      }
    }
  }
  .new-message{
    margin: 20px 0 0 0;
    form{
      display: flex;
      
      .button-element{
        width: auto;
      }
      textarea{
        margin: 0px 12px 0 0;
        flex: 1;
      }
    }
  }
}