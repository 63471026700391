@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

$spaceing: 120px;

.modal :first-child.cs-modal{
  overflow: auto;
  max-width: 920px;
  padding: 0;
}
.cs-modal{
  .cs-header{
    box-shadow: 0px 3px 8px 0px rgba(0,0,0,.1);
    border: 1px solid rgba(220,220,220,1);
    border-width: 0px 0px 1px;
    padding: 20px;
    .info{
      margin: 12px 0 0px;
      display: flex;
      >div{
        margin-right: 20px;
      }
    }
    .buttons{
      display: flex;
      .button-element {
        margin-right: 20px;
        width: auto;
      }
    }
  }
  .cs-content{
    background-color: $background-color;
    padding: 20px;
    max-height: calc(100vh - 360px);
    overflow: auto;
    .ticket-messages{
      .ticket-message{
        margin: 12px 0 0;
        &.not-staff{margin-right: $spaceing;}
        &.staff{margin-left: $spaceing;}
        &:first-of-type{margin-top: 0;}
        border-radius: 3px;
        .header{
          font-size: 13px;
          line-height: 20px;
          display: flex;
          margin: 0 0 2px;
          .button-element{
            width: auto;
            height: 20px;
            .button-status-image{
              top: 0px;
              right: 0px;
              height: 20px;
              width: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              svg,.loading{
                transform: scale(0.7);
              }
            }
            button{
              height: inherit;
              line-height: 20px;
              font-size: 12px;
              padding-left: 8px;
              padding-right: 24px;
            }
          }
        }
        &.not-staff .header{
          > div {
            margin-right: 12px;
          }
        }
        &.staff .header{
          justify-content: flex-end;
          > div {
            margin-left: 12px;
          }
        }
        .text-container{
          margin-bottom: 2px;
          background-color: #FFF;
        }
        .text{
          background-color: rgba(250,176,51,.15);
          border: 1px solid rgba(250,176,51,1);
          word-break: break-all;
          box-shadow: 0;
          margin-bottom: -4px;
        }
        &.staff .text{
          background-color: rgba($primary-color,.15);
          border-color: $primary-color;
        }
        .date{
         font-size: 13px;
        }
        &.staff .date{
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
  .cs-footer{
    border: 1px solid rgba(220,220,220,1);
    border-width: 1px 0px 0px;
    padding: 20px;
    form{
      display: flex;
      .reply-box{
        // flex: 1;
      }
      .button-element {
        margin-left: 20px;
        width: auto;
      }
    }
    textarea{
      width: auto;
      flex: 1;
      margin: 0;
      min-height: 40px;
      max-height: 360px;
    }
  }
}