@import '../../../css/styles/colors';

.password-input{
  position: relative;
  input{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .show-password{
    background-color: #FFF;
    top: 0;
    right: 0;
    position: absolute;
    cursor: pointer;
    svg{
      fill: $primary-color;
      margin: 20%;
      width: 60%;
      height: 60%;
    }
    &:hover{
      z-index: 10;
    }
  }
}
.password-input .show-password svg {fill:$sandbox-primary-color;}