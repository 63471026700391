
// Color palet
$one: #22aaa1;
$two: #f2af29;
$light-gray: #e4edf6;
$dark-gray: #4d5061;
$white: #fffffb;
$black: #30323d;

// $primary-color: rgb(196,43,1);
// $primary-color: rgb(153,31,12);
$primary-color: rgb(176,39,1);
// $primary-color: rgb(0,127,157);

// Sandbox
$sandbox-primary-color: rgb(176, 1, 135);

$base-font-color: rgba(40,40,40,1);

$secondary-color: #4d5061;
$accent-color: #f2af29;

$background-color: $light-gray;

$light-gray-color: rgba(200,200,200,1);
$gray-screen-background-color: rgba(0,0,0,.65);


$footer-background-color: $primary-color;
$sandbox-footer-background-color: $sandbox-primary-color;
$footer-font-color: #FFF;
$footer-font-secondary-color: rgba(255,255,255,.85);
